<template>
  <div class="scale-card">
    <div class="scale-card-value">
      <Scale :choices="choices" />
    </div>
    <div class="scale-card-texts">
      <div class="scale-card-texts-item">
        <FormGroup
          :label="$t('15_5_2_label_characteristics')"
          :focused="focusedCharacteristics"
        >
          <input
            type="text"
            :placeholder="$t('15_5_2_placeholder_characteristics')"
            v-model="characteristics"
            @input="validationError.characteristics = false"
            @focus="focusedCharacteristics = true"
            @blur="focusedCharacteristics = false"
            ref="characteristics"
          />
        </FormGroup>
      </div>
      <div class="scale-card-texts-item">
        <FormGroup
          :label="$t('15_5_2_label_examples')"
          :focused="focusedExamples"
        >
          <input
            type="text"
            :placeholder="$t('15_5_2_placeholder_examples')"
            v-model="examples"
            @input="validationError.examples = false"
            @focus="focusedExamples = true"
            @blur="focusedExamples = false"
            ref="examples"
          />
        </FormGroup>
      </div>
    </div>
  </div>
</template>
<script>
import Scale from "@/components/sources/components/toneOfVoice/Scale.vue";
import FormGroup from "@/components/FormGroup.vue";

export default {
  components: {FormGroup, Scale},
  props: {
    choices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      characteristics: '',
      examples:'',
      focusedExamples: false,
      focusedCharacteristics: false,


      validationError: {
        characteristics: false,
        examples: false,
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.scale-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.scale-card-value {
  width: 100%;
}

.scale-card-texts {
  min-width: 455px;
  max-width: 455px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.scale-card-texts-item {
  width: 100%;
}
</style>
