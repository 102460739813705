import { render, staticRenderFns } from "./RulesCard.vue?vue&type=template&id=05fde1fe&scoped=true&"
import script from "./RulesCard.vue?vue&type=script&lang=js&"
export * from "./RulesCard.vue?vue&type=script&lang=js&"
import style0 from "./RulesCard.vue?vue&type=style&index=0&id=05fde1fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05fde1fe",
  null
  
)

export default component.exports