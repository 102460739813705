<template>
  <div class="rules-card">
    <div class="rules-card-data">
      <div class="rules-card-title">{{ title }}</div>
      <div class="rules-card-description">{{ description }}</div>
    </div>
    <div class="rules-card-do">
      <FormGroup
        :label="$t('15_5_2_label_do')"
        label-color="#23B39A"
        :focused="focusedDo"
      >
        <textarea
          class="form-group__input large"
          :placeholder="$t('15_5_2_placeholder_do')"
          v-model="content"
          @input="validationError.content = false"
          @focus="focusedDo = true"
          @blur="focusedDo = false"
          ref="do"
        ></textarea>
      </FormGroup>
    </div>
    <div class="rules-card-dont">
      <FormGroup
        :label="$t('15_5_2_label_dont')"
        label-color="#DB0E0E"
        :focused="focusedDont"
      >
        <textarea
          class="form-group__input large"
          :placeholder="$t('15_5_2_placeholder_dont')"
          v-model="content"
          @input="validationError.content = false"
          @focus="focusedDont = true"
          @blur="focusedDont = false"
          ref="dont"
        ></textarea>
      </FormGroup>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup.vue";

export default {
  components: {
    FormGroup

  },
  computed: {

  },
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      focusedDo: false,
      focusedDont: false,
      content: '',
      validationError: {
        content: ''
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.rules-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}

.rules-card-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}

.rules-card-title {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #17252A;
}

.rules-card-description {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #888F91;

}

.rules-card-do {
  width: 100%;
}

.rules-card-dont {
  width: 100%;
}
</style>
