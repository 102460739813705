<template>
  <div class="modal-edit-channels">
    <div class="modal-edit-channels-header">
      <div class="modal-edit-channels-title">
        <strong>{{ $t('Edit Channels') }}</strong>
        <span>{{ type }}</span>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-edit-channels-body">
      <div class="column">
        <Dropdown
          :label="$t('Channels')"
          :placeholder="$t('Select or add a new one...')"
          :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
        />
        <ButtonAdd />
      </div>
    </div>

    <div class="modal-edit-channels-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('Cancel') }}</Button>
      <Button @click="handleClose" type="primary">{{ $t('Save') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
export default {
  components: {
    Button,
    Dropdown
  },
  computed: {

  },
  props: {
    type: {
      type: String,
      default: ''
    },
  },
  data() {
    return {}
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    handleClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.modal-edit-channels {
  width: 536px;
}

.modal-edit-channels-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-edit-channels-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-edit-channels-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-edit-channels-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-edit-channels-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-edit-channels-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
}

.modal-edit-channels-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}
</style>
