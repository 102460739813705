import babelPolyfill from 'babel-polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Plugins
import '@/source/gtm'
import VueLazyLoad from 'vue-lazyload'
import VCalendar from 'v-calendar'
import VTooltip from 'v-tooltip'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueMoment from 'vue-moment'
import VueCryptojs from 'vue-cryptojs'
const moment = require('moment')
require('moment/locale/en-gb')
require('moment/locale/cs')

import { createProvider } from './vue-apollo'
import VueInputAutowidth from 'vue-input-autowidth'
import TextareaAutosize from 'vue-textarea-autosize'
import Notifications from 'vue-notification'
import VueI18n from 'vue-i18n'
import vuescroll from 'vuescroll/dist/vuescroll-native'
import 'vuescroll/dist/vuescroll.css'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import VueMask from 'v-mask'
import Hotjar from 'vue-hotjar'
import { Splitpanes, Pane } from 'splitpanes';

import VueLuxon from 'vue-luxon'
Vue.use(VueLuxon)

import { URL } from '@/constants/URLConstant.js'
import VueJwtDecode from 'vue-jwt-decode'

import translation_en from '@/i18n/translation_en.js'
import translation_cs from '@/i18n/translation_cs.js'

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
require('tinymce/skins/ui/oxide/skin.min.css')

import 'splitpanes/dist/splitpanes.css';

import setupInterceptors from './services/rest-api/setupInterceptors'

Vue.use(Splitpanes)
Vue.use(Pane)
Vue.use(VueLazyLoad)
Vue.use(Notifications)
Vue.use(VCalendar, {})
Vue.use(vuescroll)
Vue.use(VTooltip)
Vue.use(FloatingVue)
Vue.use(require('vue-prevent-parent-scroll'))
Vue.component('v-select', vSelect)
Vue.use(VueMask)
Vue.use(VueCryptojs)

Vue.use(VueMoment, {
  moment,
})
Vue.use(VueInputAutowidth)
Vue.use(TextareaAutosize)
Vue.use(VueI18n)

Vue.use(Hotjar, {
  id: '3067648',
  isProduction: URL === 'https://contesaur.app' ? true : false,
})

Vue.config.productionTip = false

const messages = {
  en: translation_en,
  cs: translation_cs,
}

const i18n = new VueI18n({
  locale: 'cs',
  fallbackLocale: 'en',
  messages,
})

setupInterceptors()

let app = new Vue({
  i18n,
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app')

global.vm = app
