<template>
    <FormGroup
      :label="label"
      :focused="showData"
    >
      <div @click="toggle" class="dropdown" v-click-outside="hide">
        <div class="dropdown__selected" :class="{'is-disabled': disabled}">
          <DropdownItem v-if="selected.value" :item="selected" />

          <div v-else class="dropdown__placeholder">{{ placeholder }}</div>

          <span :class="{'rotate': showData}">
            <IcoDown />
          </span>
        </div>
        <div v-if="showData && itemList.length > 0" class="dropdown__data">
          <div
            class="dropdown__item"
            v-for="(item, index) in itemList"
            :key="index"
          >
            <DropdownItem
              :item="item"
              :index="index"
              @selectItem="selectItem(index)"
            />
          </div>
        </div>

        <div v-if="showData && itemList.length === 0" class="dropdown__data">
          <p style="padding: 15px;">{{ $t("dropdown_select_no_options") }}</p>
        </div>
      </div>
  </FormGroup>

</template>

<script>
import FormGroup from '@/components/FormGroup.vue';

import ClickOutside from 'vue-click-outside';
import IcoDown from '@/components/svg/IcoDown.vue';
import DropdownItem from "@/components/dropdown/DropdownItem.vue";

export default {
  components: {
    DropdownItem,
    FormGroup,
    IcoDown
  },
  directives: {
    ClickOutside
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    itemList() {
      let list = [];
      this.items.forEach(item => {
        list.push({
          value: item.value,
          name: item.name,
        });
      });

      list.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });

      return list;
    }
  },
  created() {
    this.selected = this.value;
  },
  data() {
    return {
      showData: false,
      selected: {},
    }
  },
  methods: {
    selectItem(index) {
      this.selected = this.itemList[index];
      this.showData = false;

      this.$emit('updateItem', this.selected);
    },
    hide() {
      this.showData = false;
      this.$emit('blur');
    },
    toggle() {
      if( this.disabled ) {
        this.showData = false
        return
      }
      this.showData = !this.showData;

      if(this.showData) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
    unsetUser() {
      this.selected = {};
      this.showData = false;
      this.$emit('updateUser', this.selected);
    }
  },
  watch: {
    value() {
      this.selected = this.value;
    }
  }
}
</script>


<style>
.sp.is-open .dropdown__selected span svg path{
  stroke: #2CE0C1 !important;
}
</style>


<style scoped>
.dropdown{
  position: relative;
  padding: 5px 15px;
}

.dropdown.slim{
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 4px;
}

.dropdown__selected{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  overflow: hidden;
}

.dropdown__selected.is-disabled{
  cursor: auto !important;
}

.dropdown__selected span{
  transform: rotate(0deg);
  transition-duration: 0.2s;
  transition-property: transform;
  display: inline-block;
  height: 8px;
  line-height: 8px;

  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
}

.dropdown__selected span svg{
  height: 8px;
  line-height: 8px;
}

.dropdown__selected span.rotate{
  transform: rotate(180deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.dropdown__placeholder{
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #C5C5C5;

  height: 30px;
  line-height: 30px;
}

.dropdown__data{
  position: absolute;
  z-index: 992;
  padding: 0;
  top: 100%;
  left: 10px;
  right: 10px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
}

.dropdown__item{
  /* margin-top: 14px; */
  cursor: pointer;
  padding: 7px 15px;
}

.dropdown__item:hover{
  background-color: #F6F6F7;
}

.dropdown__item:first-child{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown__item:last-child{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
