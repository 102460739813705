<template>
  <ContentWrapper>
    <NoStdc
      v-if="!showList"
      @add="handleShowModal"
    />

    <div v-if="showList" class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_8_1_2_title') }}</h1>

        <div class="submenu">
          <div class="submenu-count">
            <div class="submenu-count-label">{{ $t('15_8_1_2_label_items_count') }}:</div>
            3
          </div>
          <div class="submenu-items">
            <Button
              size="nav"
              type="primary"
              @click="handleShowModal"
            >
              <IcoPlus/>
              {{ $t('15_8_1_2_btn_add_std') }}
            </Button>
          </div>
        </div>

        <div class="items">
          <StdcCard @delete="handleDeleteCard" />
          <StdcCard @delete="handleDeleteCard" />
          <StdcCard @delete="handleDeleteCard" />
        </div>

      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <StdcModalDelete @close="showModalDelete = false" />
    </Modal>

    <Modal
      type="source"
      v-if="showModalAdd"
      @closeModal="showModalAdd = false"
    >
      <StdcModalAdd @close="showModalAdd = false" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import NoPersonas from "@/components/sources/components/personas/NoPersonas.vue";
import PersonaCard from "@/components/sources/components/personas/PersonaCard.vue";
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import IcoChartPie from "@/components/svg/IcoChartPie.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import NoProducts from "@/components/sources/components/products/NoProducts.vue";
import NoStdc from "@/components/sources/components/stdc/NoStdc.vue";
import StdcCard from "@/components/sources/components/stdc/StdcCard.vue";
import Modal from "@/components/Modal.vue";
import StdcModalDelete from "@/components/sources/components/stdc/StdcModalDelete.vue";
import StdcModalAdd from "@/components/sources/components/stdc/StdcModalAdd.vue";

export default {
  components: {
    StdcModalAdd,
    StdcModalDelete,
    Modal,
    StdcCard,
    NoStdc,
    NoProducts,
    ContentWrapper,
    IcoChartPie,
    PersonaCard,
    NoPersonas,
    Button,
    IcoPlus
  },
  computed: {},
  data() {
    return {
      showList: false,
      showModalDelete: false,
      showModalAdd: false,
    }
  },
  methods: {
    handleDeleteCard() {
      this.showModalDelete = true;
    },
    handleShowModal() {
      this.showList = true;
      this.showModalAdd = true;
    }
  }
}
</script>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submenu {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.submenu-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  gap: 4px;
}

.submenu-count-label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #AEB3B5;
}

.submenu-items {
  display: flex;
  gap: 16px;
}
</style>
