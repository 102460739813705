<template>
  <ContentWrapper>

    <SlickMenu title="Lean Canvas Title #1">
      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_5_1_btn_print') }}
      </Button>

      <Button size="nav" type="primary" @click="handleSave()">
        {{ $t('15_5_1_btn_save') }}
      </Button>
    </SlickMenu>

    <div class="tone-of-voice-cards">
      <div class="tone-of-voice-scales">
        <div class="boxed boxed--small boxed--first">
          <div class="box">
            <div class="tone-of-voice-scales-header">
              <div class="tone-of-voice-scales-header-title">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 20H17M6 6L12 5L18 6M6 6L9 12C9 12.7956 8.68393 13.5587 8.12132 14.1213C7.55871 14.6839 6.79565 15 6 15C5.20435 15 4.44129 14.6839 3.87868 14.1213C3.31607 13.5587 3 12.7956 3 12L6 6ZM18 6L21 12C21 12.7956 20.6839 13.5587 20.1213 14.1213C19.5587 14.6839 18.7956 15 18 15C17.2044 15 16.4413 14.6839 15.8787 14.1213C15.3161 13.5587 15 12.7956 15 12L18 6ZM12 3V20" stroke="#9795FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h2>{{ $t('15_5_1_title_scales') }}</h2>
              </div>
              <div class="tone-of-voice-scales-header-control">
                <Button @click="handleAddCustomScale" size="small"><IcoPlus color="#17252A" />{{ $t('15_5_1_add_custom_scale') }}</Button>
              </div>
            </div>

              <ScaleCard v-for="(item, index) in activeScales" :key="index" :choices="item.choices" />

            <Accordion
              :title="$t('15_5_1_accordion_title')"
              :description="$t('15_5_1_accordion_description')"
            >
              <ScaleCard v-for="(item, index) in additionalScales" :key="index" :choices="item.choices" />
            </Accordion>
          </div>
        </div>
      </div>


      <div class="tone-of-voice-rules">
        <div class="boxed boxed--small">
          <div class="box">
            <div class="tone-of-voice-rules-header">
              <div class="tone-of-voice-rules-header-title">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 7H10M7 4V10M20 18H14M5 19L19 5" stroke="#9795FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h2>{{ $t('15_5_1_title_do_dont_rules') }}</h2>
              </div>
              <div class="tone-of-voice-rules-header-control">
                <Button @click="handleAddCustomRule" size="small"><IcoPlus color="#17252A" />{{ $t('15_5_1_btn_add_custom_rule') }}</Button>
              </div>
            </div>

            <div class="tone-of-voice-rules-cards">
              <RulesCard
                v-for="(rule, index) in rulesCard"
                :key="index"
                :title="rule.title"
                :description="rule.description"
              />
            </div>

            <Accordion
              :title="$t('15_5_1_accordion_dodont_title')"
              :description="$t('15_5_1_accordion_dodont_placeholder')"
            >
              <div class="tone-of-voice-rules-cards">
                <RulesCard
                  v-for="(rule, index) in rulesCard"
                  :key="index"
                  :title="rule.title"
                  :description="rule.description"
                />
              </div>
            </Accordion>
          </div>
        </div>
      </div>

    </div>

    <Modal
      type="source"
      v-if="showModalRule"
      @closeModal="showModalRule = false"
    >
      <ModalRule
        @close="showModalRule = false"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalScale"
      @closeModal="showModalScale = false"
    >
      <ModalScale
        @close="showModalScale = false"
      />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import IcoPlus from "@/components/svg/IcoPlus.vue";
import Button from "@/components/button/Button.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import FormGroup from "@/components/FormGroup.vue";
import RulesCard from "@/components/sources/components/toneOfVoice/RulesCard.vue";
import ScaleCard from "@/components/sources/components/toneOfVoice/ScaleCard.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import Modal from "@/components/Modal.vue";
import ModalScale from "@/components/sources/components/toneOfVoice/ModalScale.vue";
import ModalRule from "@/components/sources/components/toneOfVoice/ModalRule.vue";

export default {
  components: {
    ModalRule,
    ModalScale,
    Modal,
    SlickMenu,
    ScaleCard,
    RulesCard,
    FormGroup,
    Accordion,
    ContentWrapper,
    IcoPlus,
    Button
  },
  computed: {
    activeScales() {
      const defaultScales = this.defaultScales.filter(scale => scale.type === 'active');
      return defaultScales;
    },
    additionalScales() {
      const defaultScales = this.defaultScales.filter(scale => scale.type === 'additional');
      return defaultScales;
    }
  },
  data() {
    const formalChoices = [
      {
        title: this.$t('15_5_3_scale_formal_formal'),
        value: 'formal'
      },
      {
        title: this.$t('15_5_3_scale_formal_text'),
        value: 'text_1'
      },
      {
        title: this.$t('15_5_3_scale_formal_neutral'),
        value: 'neutral'
      },
      {
        title: this.$t('15_5_3_scale_formal_text'),
        value: 'text_2'
      },
      {
        title: this.$t('15_5_3_scale_formal_casual'),
        value: 'casual'
      }
    ],
      seriousChoices = [
      {
        title: this.$t('15_5_3_scale_serious_serious'),
        value: 'formal'
      },
      {
        title: this.$t('15_5_3_scale_serious_text'),
        value: 'text_1'
      },
      {
        title: this.$t('15_5_3_scale_serious_neutral'),
        value: 'neutral'
      },
      {
        title: this.$t('15_5_3_scale_serious_text'),
        value: 'text_2'
      },
      {
        title: this.$t('15_5_3_scale_serious_playful'),
        value: 'casual'
      }
    ],
      respectfulChoices = [
      {
        title: this.$t('15_5_3_scale_respectful_respectful'),
        value: 'respectful'
      },
      {
        title: this.$t('15_5_3_scale_respectful_text'),
        value: 'text_1'
      },
      {
        title: this.$t('15_5_3_scale_respectful_neutral'),
        value: 'neutral'
      },
      {
        title: this.$t('15_5_3_scale_respectful_text'),
        value: 'text_2'
      },
      {
        title: this.$t('15_5_3_scale_respectful_irreverent'),
        value: 'irreverent'
      }
    ],
      matterOfFactChoices = [
      {
        title: this.$t('15_5_3_scale_matter_of_fact_matter_of_fact'),
        value: 'respectful'
      },
      {
        title: this.$t('15_5_3_scale_matter_of_fact_text'),
        value: 'text_1'
      },
      {
        title: this.$t('15_5_3_scale_matter_of_fact_neutral'),
        value: 'neutral'
      },
      {
        title: this.$t('15_5_3_scale_matter_of_fact_text'),
        value: 'text_2'
      },
      {
        title: this.$t('15_5_3_scale_matter_of_fact_irreverent'),
        value: 'irreverent'
      }
    ];

    return {
      showModalScale: false,
      showModalRule: false,
      defaultScales: [
        {
          selectedValue: '',
          type: 'active',
          choices: formalChoices,
        },
        {
          selectedValue: '',
          type: 'additional',
          choices: seriousChoices,
        },
        {
          selectedValue: '',
          type: 'additional',
          choices: respectfulChoices,
        },
        {
          selectedValue: '',
          type: 'additional',
          choices: matterOfFactChoices,
        }
      ],
      content: '',
      validationError: {
        content: '',
      },
      rulesCard: [
        {
          title: this.$t('15_5_3_rule_general_title'),
          description: this.$t('15_5_3_rule_general_description'),
        },
        {
          title: this.$t('15_5_3_rule_brand_title_title'),
          description: this.$t('15_5_3_rule_brand_title_description'),
        },
        {
          title: this.$t('15_5_3_rule_communication_with_customer_title'),
          description: this.$t('15_5_3_rule_communication_with_customer_description'),
        },
        {
          title: this.$t('15_5_3_rule_reaching_out_to_customers_title'),
          description: this.$t('15_5_3_rule_reaching_out_to_customers_description'),
        },
        {
          title: this.$t('15_5_3_rule_brand_glossary_title'),
          description: this.$t('15_5_3_rule_brand_glossary_description'),
        },
        {
          title: this.$t('15_5_3_rule_frequently_used_phrases_and_expressions_title'),
          description: this.$t('15_5_3_rule_frequently_used_phrases_and_expressions_description'),
        },
        {
          title: this.$t('15_5_3_rule_brand_personality_title'),
          description: this.$t('15_5_3_rule_brand_personality_description'),
        },
        {
          title: this.$t('15_5_3_rule_themes_areas_title'),
          description: this.$t('15_5_3_rule_themes_areas_description'),
        }
      ]
    }
  },
  methods: {
    handleSave() {
      try {
        this.$notify({
          title: this.$t('15_5_3_toaster_success_title_saved'),
          text: this.$t('15_5_3_toaster_success_description_saved'),
          type: 'success',
          duration: 5000,
        })
      } catch(e) {
        this.$notify({
          title: this.$t('15_5_3_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    handleAddCustomScale() {
      this.showModalScale = true;
    },
    handleAddCustomRule() {
      this.showModalRule = true;
    }
  }
}
</script>

<style scoped>
.tone-of-voice-scales-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tone-of-voice-scales-header-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tone-of-voice-scales-header-title h2{
  font-family: Nunito;
  font-size: 21px;
  font-weight: 800;
  line-height: 25.2px;
  text-align: left;
  color: #17252A;
}

.tone-of-voice-rules-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tone-of-voice-rules-header-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tone-of-voice-rules-header-title h2{
  font-family: Nunito;
  font-size: 21px;
  font-weight: 800;
  line-height: 25.2px;
  text-align: left;
  color: #17252A;
}
</style>

<style>
.tone-of-voice-rules-cards .rules-card {
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.tone-of-voice-rules-cards .rules-card:last-child {
  border-bottom: none;
  padding: 0;
  margin: 0;
}
</style>
