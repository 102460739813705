<template>
  <div
    class="dropdown-item"
    :class="{'is-active': isActive}"
    @click="selectItem()"
  >
    <div class="dropdown-item__data">
      <div>
        <div class="dropdown-item__name">
          {{ item.name }}
        </div>
      </div>
    </div>

    <button v-if="isActive" class="dropdown-item__delete">
      <IcoCross />
    </button>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue';

import IcoCross from '@/components/svg/small/IcoCross.vue';

export default {
  components: {
    Avatar,
    IcoCross
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    selected: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    isActive() {

      if( !this.isEmpty(this.selected) ) {
        if( this.selected.id === this.user.id ) {
          return true;
        }
      }

      return false;
    }
  },
  methods: {
    selectItem() {
      if(this.isActive) {
        this.unsetUser();
        return;
      }
      this.$emit("selectItem", this.user);
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    unsetUser() {
      this.$emit("unsetUser");
    }
  }
}
</script>

<style scoped>
.dropdown-item{
  position: relative;
  display: block;
  width: 100%;
  padding-right: 12px;
}

.dropdown-item__data{
  display: block;
  width: 100%;
  max-width: 220px;
}

.dropdown-item__data > div{
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-item__name{

  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #17252A;

  word-wrap: break-word;
  overflow: hidden;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-item__delete{
  position: absolute;
  top: 2px;
  right: -5px;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
</style>
